// ProviderDashboard.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './UserProfile.css'; // Reuse the UserProfile styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function ProviderDashboard() {
  const { providerId } = useParams();
  const [providerDetails, setProviderDetails] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProviderDetails = async () => {
      try {
        const docRef = doc(db, 'providers', providerId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const providerData = docSnap.data();
          setProviderDetails(providerData);

          // Fetch services based on serviceIds
          if (providerData.serviceIds && providerData.serviceIds.length > 0) {
            const servicesList = [];
            for (const serviceId of providerData.serviceIds) {
              const serviceRef = doc(db, 'services', serviceId);
              const serviceSnap = await getDoc(serviceRef);
              if (serviceSnap.exists()) {
                servicesList.push({ id: serviceId, ...serviceSnap.data() });
              }
            }
            setServices(servicesList);
          }
        }
      } catch (error) {
        console.error('Error fetching provider details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProviderDetails();
  }, [providerId]);

  const handleServiceClick = (serviceId) => {
    // Placeholder for service analytics page navigation
    console.log(`Clicked on service: ${serviceId}`);
  };

  return (
    <div className="user-profile">
      {/* Back Icon */}
      <FontAwesomeIcon
        icon={faArrowLeft}
        className="back-icon"
        onClick={() => navigate(-1)}
      />
      {loading ? (
        <p>Loading provider details...</p>
      ) : (
        <>
          {providerDetails ? (
            <div>
              <h1>Your Provider Dashboard</h1>
              <h2>Your Services</h2>
              <div className="favorites-list">
                {services.length > 0 ? (
                  services.map((service) => (
                    <div
                      key={service.id}
                      className="favorite-service"
                      onClick={() => handleServiceClick(service.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={service.banner || 'https://via.placeholder.com/150'}
                        alt={service.name}
                        className="favorite-service-image"
                      />
                      <h3>{service.name}</h3>
                      <p>{service.description}</p>
                    </div>
                  ))
                ) : (
                  <p>You have no services yet.</p>
                )}
              </div>
            </div>
          ) : (
            <p>No provider details found.</p>
          )}
        </>
      )}
    </div>
  );
}

export default ProviderDashboard;
