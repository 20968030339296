import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'; // Firebase auth instance
import './LandingPage.css';
import Profile from '../../assets/icons/user.png';

function LandingPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const motto = "Nourishing Nostalgia";
  const mottoSpans = motto.split("").map((char, index) => (
    <span key={index} style={{ '--i': index }}>{char}</span>
  ));

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    auth.signOut().then(() => setUser(null));
  };

  const handleGetStarted = () => {
    navigate('/enter-location', { state: { name: user?.displayName || '' } });
  };

  const openProfile = () => {
    navigate('/user-profile');
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      <div className="login-header">
        {user ? (
          <>
            <button className="login-button" onClick={handleLogout}>Logout</button>
            <img src={Profile} onClick={openProfile} alt="Profile Icon" className="profile-icon" />
          </>
        ) : (
          <button className="login-button" onClick={handleLogin}>Login</button>
        )}
      </div>

      {/* Hero Section */}
      <div className="title-container">
        <img className="title-logo" src="/logo.png" alt="logo" />
        <h1 className="title">Servillia</h1>
        <h2 className="motto">{mottoSpans}</h2>
        <button className="get-started-button hero-button" onClick={handleGetStarted}>
          Get Started
        </button>
      </div>

      {/* Features Section */}
      <div className="features-section">
        <div className="features-grid">
          <div className="feature-card">
            <h3>Discover Services</h3>
            <p>Find culturally specific services like food, hair, and local businesses.</p>
          </div>
          <div className="feature-card">
            <h3>Connect Easily</h3>
            <p>View detailed service pages and reach out to providers seamlessly.</p>
          </div>
          <div className="feature-card">
            <h3>Support Businesses</h3>
            <p>Help local businesses grow while accessing the services you need.</p>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="testimonials-section">
        <h2>What Our Users Say</h2>
        <div className="testimonial">
          <p>"Servillia helped me find the best Jollof rice in town!"</p>
          <span>- Grace A.</span>
        </div>
        <div className="testimonial">
          <p>"My business has grown tremendously since joining Servillia."</p>
          <span>- Ahmed B.</span>
        </div>
      </div>

      {/* CTA Section */}
      <div className="cta-section">
        <div className="cta-user">
          <h3>Looking for Services?</h3>
          <button className="cta-button" onClick={handleGetStarted}>Get Started</button>
        </div>
        <div className="cta-provider">
          <h3>Are You a Service Provider?</h3>
          <button className="cta-button" onClick={() => navigate('/become-service-provider')}>
            List Your Service
          </button>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="footer">
        <p>© 2024 Servillia. All Rights Reserved.</p>
        <div className="footer-links">
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/contact">Contact Us</a>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
